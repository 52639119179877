<template>
  <div>
    <svg :height="`${height}px` || '20px'" :width="`${width}px` || '22px'" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.0191 6.6544C17.9268 6.56083 17.875 6.43464 17.875 6.30316V1.5625C17.875 1.28636 17.6511 1.0625 17.375 1.0625H14.9375C14.6614 1.0625 14.4375 1.28636 14.4375 1.5625V3.34436C14.4375 3.43173 14.3333 3.47708 14.2694 3.41756L11.3419 0.693167C11.1494 0.514048 10.8511 0.514595 10.6593 0.69442L0.922419 9.82273C0.591743 10.1327 0.811122 10.6875 1.26439 10.6875H2.25C2.52614 10.6875 2.75 10.9114 2.75 11.1875V19.125C2.75 19.4011 2.97386 19.625 3.25 19.625H8.4375C8.71364 19.625 8.9375 19.4011 8.9375 19.125V13.25C8.9375 12.9739 9.16136 12.75 9.4375 12.75H12.5625C12.8386 12.75 13.0625 12.9739 13.0625 13.25V19.125C13.0625 19.4011 13.2864 19.625 13.5625 19.625H18.75C19.0261 19.625 19.25 19.4011 19.25 19.125V11.1875C19.25 10.9114 19.4739 10.6875 19.75 10.6875H20.8039C21.2478 10.6875 21.4716 10.1522 21.1598 9.83626L18.0191 6.6544Z"
        :fill="`${color}`"
      />
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#3b82f6'
    },
    width: {
      type: String,
      default: '22'
    },
    height: {
      type: String,
      default: '20'
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
